import React, { lazy, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { hot } from 'react-hot-loader/root';
import { useQuery } from '@apollo/react-hooks';
import { last } from 'lodash';

import Page, { Breadcrumbs, Content } from '../../library/page/page';
import LoadingSpinner from '../../components/loading-spinner/loading-spinner';
import DefaultLayout from '../../layouts/default-layout/default-layout';

import { GET_CMS_PAGE } from './graphql/cms-page-queries';

import { useDataLayer } from '../../shared/hooks/hooks';

import { trackAction } from '../../shared/utils/analytics/analytics';
import { parseCmsContentAndMapComponents } from '../../shared/utils/cms';
import { getCmsPageId, getCmsSiteId, metaTags } from '../../shared/utils/page';
import { isNill } from '../../shared/utils/validators';

import {
  PAGE_NAME,
  PAGE_SUBCATEGORY,
  PAGE_TYPE
} from '../../shared/constants/data-layer';

import './cms-page.scss';

const ScheduleAppointmentButton = lazy(() =>
  import(
    /* webpackChunkName: "schedule-appointment-button" */
    '../../components/schedule-appointment-button/schedule-appointment-button'
  )
);

const TireSizeCalculator = lazy(() =>
  import(
    /* webpackChunkName: "tire-size-calculator" */
    '../../components/tire-size-calculator/tire-size-calculator'
  )
);

const TirePressureCostCalculator = lazy(() =>
  import(
    /* webpackChunkName: "tire-pressure-cost-calculator" */
    '../../components/tire-pressure-cost-calculator/tire-pressure-cost-calculator'
  )
);

export const componentMap = new Map([
  ['calculators/tire-size', TireSizeCalculator],
  ['calculators/low-tire-pressure-cost', TirePressureCostCalculator],
  ['schedule-appointment-button', ScheduleAppointmentButton]
]);

const pagesToAddEventTrackingRegExp =
  /\/customer-service\/financing|\/fleet|\/promotions/;

function CMSPage() {
  const cmsPageId = getCmsPageId(window.location.pathname);
  const { data, loading, error } = useQuery(GET_CMS_PAGE, {
    variables: {
      id: cmsPageId,
      siteId: getCmsSiteId()
    }
  });
  const { updateDataLayer } = useDataLayer();

  const { cms } = data || {};
  const { page: cmsPage } = cms || {};
  const {
    documentTitle = '',
    htmlContent = '',
    metaTags: metaData,
    breadcrumbs = []
  } = cmsPage || {};

  const content = parseCmsContentAndMapComponents(htmlContent, componentMap);

  let pageBreadcrumbs = [{ name: 'home', url: '/' }];
  const cmsPageBreadcrumbs = cmsPage?.breadcrumbs;

  if (!window.isPreRendering) {
    pageBreadcrumbs = breadcrumbs;
  }

  useEffect(() => {
    if (!isNill(cmsPageBreadcrumbs)) {
      updateDataLayer({
        [PAGE_NAME]: breadcrumbs.reduce(
          (acc, b, i) =>
            acc + `${b.name}${breadcrumbs.length - 1 !== i ? ' : ' : ''}`,
          ''
        ),
        [PAGE_SUBCATEGORY]:
          breadcrumbs.length > 0 ? `${last(breadcrumbs).name}` : '',
        [PAGE_TYPE]: 'content'
      });
    }
  }, [breadcrumbs, cmsPageBreadcrumbs, updateDataLayer]);

  useEffect(() => {
    let pageEventTracking;

    if (pagesToAddEventTrackingRegExp.test(cmsPageId)) {
      (async function () {
        const module = await import('./utils/cms-page-event-tracking');
        pageEventTracking = module.default;

        window.addEventListener('click', pageEventTracking);
      })();
    }
    if (documentTitle.includes('404')) {
      trackAction('error_path_url', { url: window.location.href });
    }
    return () => {
      if (pageEventTracking) {
        window.removeEventListener('click', pageEventTracking);
      }
    };
  }, [cmsPageId, documentTitle]);

  if (error && !window.isPreRendering) {
    throw error;
  }

  return (
    <DefaultLayout>
      {!window.isPreRendering && (
        <Helmet>
          <title>{documentTitle}</title>
          {metaTags(metaData || [])}
          {documentTitle === '404' && (
            <meta content="404" name="prerender-status-code" />
          )}
        </Helmet>
      )}
      <Page>
        {!window.isPreRendering && pageBreadcrumbs && (
          <Breadcrumbs crumbs={pageBreadcrumbs} />
        )}
        {!window.isPreRendering && (
          <>
            {loading ? (
              <LoadingSpinner styleName="loading" />
            ) : (
              <Content>{content}</Content>
            )}
          </>
        )}
      </Page>
    </DefaultLayout>
  );
}

export default hot(CMSPage);
