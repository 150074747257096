import React from 'react';

import { AngleLeftLightIcon } from '../../../../shared/icons/icons';
import Link from '../../../link/link';

import './breadcrumbs.scss';

interface BreadcrumbsProps {
  className?: string;
  crumbs: {
    name: string;
    url?: string;
  }[];
}

function Breadcrumbs(props: BreadcrumbsProps) {
  const { className, crumbs } = props;

  return Array.isArray(crumbs) && crumbs.length > 0 ? (
    <div className={`${className || ''} breadcrumbs`} styleName="container">
      <ol className="breadcrumbs__list" styleName="crumbs">
        {crumbs.map((crumb, idx) => (
          <li
            className="breadcrumbs__list-item"
            key={`bc-${idx}`}
            styleName="crumb"
          >
            {crumb.url ? (
              <Link
                className="dt-link breadcrumbs__list-item-link"
                styleName="crumb-link"
                to={crumb.url}
              >
                <AngleLeftLightIcon
                  className="breadcrumbs__list-item-link-icon"
                  styleName="crumb-arrow"
                />
                {crumb.name}
              </Link>
            ) : (
              <span>{crumb.name}</span>
            )}
          </li>
        ))}
      </ol>
    </div>
  ) : null;
}

export default Breadcrumbs;
